/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // mobile dropdown navigation styles - MAIN NAV
        $('#navbarResponsive').on('shown.bs.collapse', function () {
          $('button.navbar-toggler.ready').removeClass('ready');
        });

        $('.slides').slick({
          dots: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4500,
          speed: 500,
          fade: true,
          cssEase: 'linear',
          rows: 0
        });

        $(window).load(function(){
        	$(".verified").click(function(){
                createCookie('over21','yessir');
              });
          var x = readCookie('over21');
          if (x) {
          	// nada
          } else {
            $('#ageverify').modal({
              backdrop: 'static',
              keyboard: false
            });
          }
        });


/*
        $(window).scroll(function(){
          if ($(window).scrollTop() >= 46) {
            $('.banner .main-header').addClass('fixed-header');
          }
          else {
            $('.banner .main-header').removeClass('fixed-header');
          }
        });
*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // WooCommerce
    'woocommerce_page': {
      init: function() {
				$('.products .woocommerce-loop-product__link').matchHeight();
				$('.products .woocommerce-loop-product__link .woocommerce-loop-product__title').matchHeight();
				$('.banner #menu-primary-navigation .menu-store').addClass('active');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'woocommerce_checkout': {
      init: function() {
        $('#customer_details').addClass('row');
        $('#customer_details').removeClass('col2-set');
        $('#customer_details >.col-1,#customer_details >.col-2').addClass('col-12 col-md-6');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
